import * as React from 'react';
import { Button, FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';
import { useLocation } from 'react-router-dom';
import { Household, HouseholdPerson } from 'core/api';
import { useUserSelector } from 'state/user/userSlice';
import {
  useHouseholdsSelector,
  useGroupedHouseholdPeopleSelector,
  usePrimaryHouseholdIdSelector,
} from 'state/households/householdSlice';
import { useFormSelector } from 'state/form/formSlice';
import {
  updateRegistration,
  useInCartRegistrationsSelector,
} from 'state/registration/registrationSlice';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { CartButton } from 'components/shared/CartButton';
import { LabelButton } from '@teamsnap/snap-ui';
import { ActionContainer } from 'components/shared';
import { Header } from 'components/Form';
import HouseholdMemberCard from './_sections/HouseholdMemberCard';
import { ManageHouseholdMember } from './_sections/ManageHouseholdMember/ManageHouseholdMember';
import { current } from '@reduxjs/toolkit';

export default function SelectParticipantPage() {
  const [isAddOpen, setIsAddOpen] = React.useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [currentMember, setCurrentMember] = React.useState<HouseholdPerson>();
  const [selectedMember, setSelectedMember] = React.useState<HouseholdPerson>();

  const registrations = useInCartRegistrationsSelector();
  const primaryHouseholdId = usePrimaryHouseholdIdSelector();
  const households = useHouseholdsSelector();
  const groupedHouseholds = useGroupedHouseholdPeopleSelector();
  const user = useUserSelector();

  const currentForm = useFormSelector();
  const { organizationName } = currentForm || {};

  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation() as {
    state: { isConfirmOpen: boolean; member: HouseholdPerson };
  };
  /*
    NOTE: Investigate on an better way to handle this if, not sure if
    I can delete it because it looks like it covers some edge cases.
  */
  if (
    !isConfirmOpen &&
    location &&
    location.state &&
    location.state.isConfirmOpen
  ) {
    setIsConfirmOpen(location.state['isConfirmOpen']);
    setCurrentMember(location.state['member']);
    setSelectedMember(location.state['member']);
  }

  const { childrenAndTeensList, adultsList } = React.useMemo(() => {
    if (!households) {
      return { childrenAndTeensList: [], adultsList: [] };
    }
    const lists = getChildrenAndAdultsSortedLists(
      households,
      groupedHouseholds
    );
    return lists;
  }, [households, groupedHouseholds]);

  const handleOnParticipantAdd = () => {
    setIsEditOpen(false);
    setIsAddOpen(true);
  };

  const handleOnParticipantEdit = (member: HouseholdPerson) => {
    setCurrentMember(member);
    setIsAddOpen(false);
    setIsEditOpen(true);
    setIsConfirmOpen(false);
  };

  const handleOnParticipantSelect = (member: HouseholdPerson) => {
    setHasError(false);
    setSelectedMember(member);

    // WARNING: This logic is not clear, confirm with QA
    if (currentForm) {
      setIsAddOpen(false);
      setIsEditOpen(false);
      setIsConfirmOpen(true);
      setCurrentMember(member);
    }
  };

  const goNext = () => {
    if (selectedMember) {
      navigate(`/form/${currentForm?.id}/selectGroup`);
    } else {
      setHasError(true);
    }
  };

  React.useEffect(() => {
    dispatch(
      updateRegistration({
        currentMember: undefined,
        currentGroup: undefined,
      })
    );
  }, []);

  return (
    <>
      <ActionContainer
        displayBackButton
        goBackAction={() => navigate(`/form/${currentForm?.id}`)}
        action={() => goNext()}
        actionLabel="Next"
        header={
          <Header
            title={organizationName || 'Select Participant'}
            navigation={
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                icon="arrow-left"
                type="link"
                size="large"
                onClick={() => navigate(`/form/${currentForm?.id}`)}
              />
            }
            rightIcon={<CartButton />}
            profileName={`${user?.firstName} ${user?.lastName}`}
          />
        }
      >
        <header className="sui-mb-4">
          <h3 className="sui-heading-lg sui-mb-0.5">
            Who are you registering today?
          </h3>
          <p className="sui-body">Please select one at a time.</p>
        </header>

        {hasError && (
          <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
            Please select a household member.
          </FieldMessage>
        )}

        {childrenAndTeensList.length > 0 && (
          <>
            <h3 className="sui-heading-sm sui-mb-1">Children and Teens</h3>
            <section className="sui-mb-4 sui-grid sui-gap-1">
              {childrenAndTeensList.map((member) => (
                <HouseholdMemberCard
                  key={`child-teen-member-card-${member.id}`}
                  member={member}
                  memberRegistrations={registrations.filter(
                    (registration) => registration.householdPersonId === member.id
                  )}
                  selected={selectedMember?.id === member.id}
                  onClick={() => handleOnParticipantSelect(member)}
                  onClickEdit={() => handleOnParticipantEdit(member)}
                  iconDisabled={false}
                />
              ))}
            </section>
          </>
        )}

        {adultsList.length > 0 && (
          <>
            <h3 className="sui-heading-sm sui-mb-1">Adults</h3>
            <section className="sui-mb-4 sui-grid sui-gap-1">
              {adultsList.map((member) => (
                <HouseholdMemberCard
                  key={`adult-member-card-${member.id}`}
                  member={member}
                  memberRegistrations={registrations.filter(
                    (registration) =>
                      registration.householdPersonId === member.id
                  )}
                  selected={selectedMember?.id === member.id}
                  onClick={() => handleOnParticipantSelect(member)}
                  onClickEdit={() => handleOnParticipantEdit(member)}
                  iconDisabled={false}
                />
              ))}
            </section>
          </>
        )}

        <LabelButton
          className="sui-w-full"
          variantType="tertiary"
          icon="add"
          labelText="Add a household member"
          data-testid="add-household-member"
          onClick={handleOnParticipantAdd}
        />
      </ActionContainer>

      {isAddOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isAddOpen}
          households={households}
          setIsOpen={setIsAddOpen}
          type="add"
          canRemoveHouseholdMember={true}
        />
      )}
      {isEditOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          type="edit"
          householdMember={currentMember}
          canRemoveHouseholdMember={true}
        />
      )}
      {isConfirmOpen && (
        <ManageHouseholdMember
          primaryHouseholdId={primaryHouseholdId}
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          type="confirm"
          householdMember={currentMember}
          onCancel={() => {
            setSelectedMember(undefined);

            // Reset prev state
            if (
              isConfirmOpen &&
              location &&
              location.state &&
              location.state['isConfirmOpen']
            ) {
              navigate(`/form/${currentForm?.id}/selectParticipant`);
            }
          }}
          canRemoveHouseholdMember={true}
        />
      )}
    </>
  );
}

function getChildrenAndAdultsSortedLists(
  households: Household[],
  groupedHouseholds: Record<string, HouseholdPerson[]>
): {
  childrenAndTeensList: HouseholdPerson[];
  adultsList: HouseholdPerson[];
} {
  /*
    NOTE: The logic here is:
    - Get all members from all households.
    - Sort them by firstName and lastName
  */
  const allMembersFromAllHouseholds = households
    .reduce(
      (acc, household) => [...acc, ...groupedHouseholds[household.id]],
      [] as HouseholdPerson[]
    )
    .sort((a, b) => {
      if (a.person?.firstName === b.person?.firstName) {
        return a.person?.lastName.localeCompare(b.person?.lastName);
      }
      return a.person?.firstName.localeCompare(b.person?.firstName);
    });

  // NOTE: Separate children and adults
  const childrenAndTeensList: HouseholdPerson[] = [];
  const adultsList: HouseholdPerson[] = [];
  allMembersFromAllHouseholds.forEach((member) => {
    if (typeof member.person?.age === 'number' && member.person?.age < 18) {
      childrenAndTeensList.push(member);
    } else {
      adultsList.push(member);
    }
  });

  return { childrenAndTeensList, adultsList };
}
